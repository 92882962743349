<!--
 * @Author: wjc
 * @Date: 2023-11-17 17:39:16
 * @LastEditors: LiZhiWei
<<<<<<< HEAD
 * @LastEditTime: 2024-05-11 12:05:28
=======
 * @LastEditTime: 2024-05-11 12:57:33
>>>>>>> 6542e2584ad23c31751578618b2495c6ca7a8874
 * @Description: 
-->
<template>
  <div class="login-container flex flex-col justify-between items-center px-60px py-30px">
    <img
      src="/images/login-logo.png"
      class="w-full md:h-54px sm:h-36px md:self-start xs:self-center xs:mb-16px cursor-pointer"
      @click="navigateTo('/')"
    />
    <div class="bg-white rounded-10px md:w-480px sm:380px md:p-40px xs:p-20px">
      <div class="login flex-1">
        <div class="mb-40px">
          <div class="font-s-3.6rem text-color-1">账号登录</div>
          <div class="text-color-place font-s-1.6rem">Sign in</div>
        </div>
        <div>
          <el-form
            ref="loginFormRef"
            :rules="loginFormRules"
            :model="loginForm"
            label-position="top"
            hide-required-asterisk
            @keydown.enter="onLogin"
          >
            <el-form-item prop="username" label="账号">
              <el-input
                v-model="loginForm.username"
                size="large"
                name="username"
                autocomplete="new-password"
                :autofocus="true"
              ></el-input>
            </el-form-item>
            <el-form-item prop="password" label="密码">
              <el-input
                v-model="loginForm.password"
                type="password"
                name="password"
                size="large"
                autocomplete="new-password"
              ></el-input>
            </el-form-item>
            <div>
              <el-button
                class="w-full h-54px! mb-16px rounded-27px!"
                type="primary"
                round
                :loading="loading"
                :disabled="loading"
                @click="onLogin"
              >
                登录
              </el-button>
              <div class="flex items-center text-color-place">
                <i class="i-ep-warning wh-16px mr-4px"></i>
                如遇登录问题，请联系协会相关人员
              </div>
              <!-- <div class="flex justify-end items-center w-full px-20px font-s-1.4rem">
                <span
                  class="cursor-pointer text-color-place"
                  @click="() => navigateTo('/register')"
                >
                  注册
                </span>
              </div> -->
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <div class="flex justify-between flex-wrap w-full color-white sm:items-center">
      <div class="md:m-0 xs:mx-auto">
        2015 © {{ year }} {{ config.footerWrite.url }} {{ appName }}
      </div>
      <div class="md:m-0 xs:mx-auto">
        <span>琼公网安备：46010802000270号</span>
        <span class="ml-4px">琼ICP备00000000号 </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import dayjs from 'dayjs'
  import type { FormInstance, FormRules } from 'element-plus'
  import { ElMessageBox } from 'element-plus'
  import { useLoginStore } from '~/stores/modules/user/login'
  import { useUserStore } from '~/stores/modules/user/user'
  import config, { appName } from '~/constants'
  import { AuditStatusEnum } from '@/models/enums'

  definePageMeta({
    layout: false,
  })
  defineOptions({
    name: 'Login',
  })

  const year = ref(dayjs().format('YYYY'))
  const loginStore = useLoginStore()
  const userStore = useUserStore()
  const loginFormRef = ref<FormInstance>()
  const loginForm = ref({
    username: '',
    password: '',
  })
  const loginFormRules = ref<FormRules>({
    username: [{ required: true, message: '请输入账号' }],
    password: [{ required: true, message: '请输入密码' }],
  })
  const loading = ref(false)

  const onLogin = () => {
    loginFormRef.value?.validate(async (valid: boolean) => {
      if (valid) {
        loading.value = true
        const params = {
          username: loginForm.value.username,
          password: encryptByMd5(loginForm.value.password),
          clientId: getCanvasFingerprint(),
          userType: 'ENTERPRISE',
        }
        loginStore
          .login(params)
          .then((res) => {
            if (res) {
              getUserInfo()
            }
          })
          .finally(() => {
            loading.value = false
          })
      }
    })
  }

  const getUserInfo = async () => {
    const userInfo = await userStore.getUserEnterpriseInfo()
    // 获取企业信息 根据状态跳转
    // 无企业信息： 提示，跳转用户页面-编辑
    // 审核中：跳转用户页面-预览
    // 审核失败：跳转用户页面-编辑
    // 审核通过: 跳转企业详情页
    if (!userInfo || !userInfo.id) {
      ElMessageBox.confirm(
        '<strong>您尚未提交企业基本情况，请填写并提交企业基本情况完成企业注册！</strong>',
        '',
        {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '填写',
          cancelButtonText: '退出登录',
          closeOnClickModal: false,
          confirmButtonClass: 'el-button el-button--primary',
          cancelButtonClass: 'el-button el-button--danger is-plain',
        }
      )
        .then(() => {
          toUserPage('edit')
        })
        .catch(() => {
          loginStore.logout()
        })
      return
    }
    const { auditStatus = '' } = userInfo
    if (auditStatus === AuditStatusEnum.APPROVED) {
      toEnterpriseDetailPage(userInfo.id)
    } else {
      const status = ['review'].includes(auditStatus) ? 'view' : 'edit'
      toUserPage(status)
    }
  }

  /**
   * 根据状态跳转 用户信息页
   * @param status 审核状态
   */
  const toUserPage = (status: string) => {
    navigateTo({
      path: '/admin/user',
      query: {
        type: status,
      },
    })
  }

  /**
   * 跳转企业详情页
   */
  const toEnterpriseDetailPage = (id: string) => {
    navigateTo({
      path: `/admin`,
    })
  }
</script>

<style scoped lang="scss">
  .login-container {
    @apply wh-full;
    background: url('/images/login-bg.jpg') no-repeat;
    background-size: cover;
    img {
      width: min-content;
    }
  }
</style>
